import AlertIcon from '../svgComponents/AlertIcon';
import Checkbox from '@mui/material/Checkbox';
import CheckedCheckbox from '../svgComponents/CheckedCheckbox';
import LeftArrow from '../svgComponents/LeftArrow';
import React, { memo, useContext, useEffect, useState } from 'react';
import RightArrow from '../svgComponents/RightArrow';
import StatusDocument from './StatusDocument';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import UncheckedCheckbox from '../svgComponents/UncheckedCheckbox';
import WorkflowID from './WorkflowID';
import globalStyle from './customStyleButton';
import { TabsContext } from '../../helpers/TabsContext';

function RowDocumentation({ checkDocument, row, addToDocumentsID, removeFromDocumentsID, userIsBlocked, currentArchiveTab }) {
    const [checkbox, setCheckbox] = useState(false);
    useEffect(() => {
        setCheckbox(row.checked);
        row.checked ? addToDocumentsID(row.workflowId) : removeFromDocumentsID(row.workflowId);
        return () => setCheckbox(false);
    }, [row.checked]);
    const { currentPageTab } = useContext(TabsContext);

    const changeCheckbox = () => {
        checkDocument(row.id);
    };
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    let date = new Date();
    date.setDate(date.getDate() + 3);

    const setArrowIcon = () => {
        if (userIsBlocked) {
            if (currentPageTab === 1 || currentArchiveTab === 1) return <RightArrow />;
            else return <LeftArrow />;
        } else {
            if (currentPageTab === 2 || currentArchiveTab === 0) return <RightArrow />;
            else return <LeftArrow />;
        }
    };

    return (
        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
            <TableCell sx={{ width: '6%', verticalAlign: 'baseline' }}>
                <Checkbox
                    disableRipple
                    checked={checkbox}
                    onChange={changeCheckbox}
                    icon={<UncheckedCheckbox />}
                    checkedIcon={<CheckedCheckbox />}
                    sx={{
                        transform: `scale(${windowWidth / 1920})`,
                        color: '#BDBDBD',
                        '&.Mui-checked': {
                            color: '#8AB528',
                        },
                    }}
                />
            </TableCell>
            <TableCell sx={{ ...globalStyle.customStyleTable, width: '9%', fontWeight: row.is_read ? '400' : '600' }}>
                {row.date.split('T')[0].split('-').reverse().join('.')}
            </TableCell>
            <TableCell sx={{ ...globalStyle.customStyleTable, width: '16%', wordBreak: 'break-word', fontWeight: row.is_read ? '400' : '600' }}>
                {row.company}
            </TableCell>
            <TableCell sx={{ ...globalStyle.customStyleTable, width: '24%', wordBreak: 'break-word', fontWeight: row.is_read ? '400' : '600' }}>
                {row.document}
                {<WorkflowID workflowId={row.workflowId} />}
            </TableCell>
            <TableCell sx={{ ...globalStyle.customStyleTable, width: '17%' }}>
                {currentPageTab === 1 ? (
                    <StatusDocument
                        documentStatus={row?.recipientDocumentStatus?.toUpperCase()}
                        senderSignatureStatus={row?.senderSignatureStatus?.toLowerCase()}
                        recipientSignatureStatus={row?.recipientSignatureStatus?.toLowerCase()}
                    />
                ) : (
                    <StatusDocument
                        documentStatus={row?.senderDocumentStatus?.toUpperCase()}
                        senderSignatureStatus={row?.senderSignatureStatus?.toLowerCase()}
                        recipientSignatureStatus={row?.recipientSignatureStatus?.toLowerCase()}
                    />
                )}
            </TableCell>
            <TableCell sx={{ ...globalStyle.customStyleTable, width: '17%', fontWeight: row.is_read ? '400' : '600' }}>
                <div className="row__signature-status">
                    {setArrowIcon()}
                    <span style={{ paddingLeft: '4px' }}>{row.senderSignatureStatus.toLowerCase()}</span>
                    {row.senderSignatureStatus.toLowerCase() === 'не валидирована' ? (
                        <div className="alert">
                            <AlertIcon />
                        </div>
                    ) : (
                        ''
                    )}
                </div>

                <div className="row__signature-status">
                    {(row.recipientSignatureStatus && currentPageTab === 2 && <LeftArrow />) || (row.recipientSignatureStatus && <RightArrow />)}
                    <span style={{ paddingLeft: '4px' }}>{row.recipientSignatureStatus.toLowerCase()}</span>
                    {row.recipientSignatureStatus.toLowerCase() === 'не валидирована' ? (
                        <div className="alert">
                            <AlertIcon />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </TableCell>
        </TableRow>
    );
}
export default memo(RowDocumentation);
