import ArchiveActiveIcon from '../svgComponents/ArchiveActiveIcon';
import ArchiveDisableIcon from '../svgComponents/ArchiveDisableIcon';
import Button from '@mui/material/Button';
import CancelIcon from '../svgComponents/CancelIcon';
import CancelIconDisabled from '../svgComponents/CancelIconDisabled';
import CircularProgress from '@mui/material/CircularProgress';
import DownloadIcon from '../svgComponents/DownloadIcon';
import DownloadIconDisabled from '../svgComponents/DownloadIconDisabled';
import ModalChoose from '../Modal/ModalChoose';
import ModalError from '../Modal/ModalError';
import ModalSign from '../Modal/ModalSign';
import ModalSignSending from '../Modal/ModalSignSending';
import ModalWaiting from '../Modal/ModalWaiting';
import React, { useContext, useState } from 'react';
import Signature from '../svgComponents/Signature';
import SignatureDisabled from '../svgComponents/SignatureDisabled';
import TrashIcon from '../svgComponents/TrashIcon';
import TrashIconDisabled from '../svgComponents/TrashIconDisabled';
import api from '../../api/api';
import globalStyle from './customStyleButton';
import styled from '@emotion/styled';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ScrollContext } from '../../helpers/ScrollContext';
import ModalArchive from '../Modal/ModalArchive';

const CustomButton = styled(Button)(({ theme }) => ({
    color: '#181818',
    '&:hover, &.Mui-focusVisible': {
        backgroundColor: '#BDBDBD',
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.15,
        },
        '& .MuiImageMarked-root': {
            opacity: 0,
        },
        '& .MuiTypography-root': {
            border: '4px solid currentColor',
        },
    },
}));

export default function ButtonsPanelInComing({
    documentsID,
    setDocumentsID,
    uncheckAll,
    downloadFiles,
    declineFiles,
    deleteFiles,
    dataURLtoFile,
    refetch,
    isButtonsDisabled,
    readFileRecursive,
    signOrDeclineButtonActive,
    isScrolled,
    isDownloading,
    isArchive,
    setIsOpenModalArchive,
    isOpenModalArchive,
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenChosen, setIsOpenChosen] = useState(false);
    const [isOpenSigned, setIsOpenSigned] = useState(false);
    const [isOpenError, setIsOpenError] = useState(false);
    const [isOpenWaiting, setIsOpenWaiting] = useState(false);
    const [docsCount, setDocsCount] = useState(1);
    const [error, setError] = useState(null);
    const [modalType, setModalType] = useState('');
    const { scroll } = useContext(ScrollContext);
    const [isLoadingArchive, setIsLoadingArchive] = useState(false);

    const columns = [
        { id: 'check', label: '', width: '7%' },
        { id: 'date', label: 'Дата', width: '9.8%' },
        { id: 'sender', label: 'Отправитель', width: '18%' },
        {
            id: 'document',
            label: 'Пакет документов, ID',
            width: '27.2%',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'statusDocument',
            label: 'Статус пакета документов',
            width: '19%',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'signatureStatus',
            label: 'Статус подписи',
            width: '15%',
            format: (value) => value,
        },
    ];

    const putDocumentsInArchive = async () => {
        setIsLoadingArchive(true);
        const res = await api.patch('/archive', {
            documents_type: 'incoming',
            document_workflow_ids: documentsID,
        });

        setDocumentsID([]);
        uncheckAll();
        await refetch('/incoming-documents');
        setIsLoadingArchive(false);
    };

    return (
        <Box
            sx={{
                boxShadow: scroll ? '0px 3px 5px rgba(0, 0, 0, 0.2)' : 'none',
                pt: '1.042vw',
                position: 'sticky',
                top: 0,
                marginLeft: '-2.08vw',
                paddingLeft: '2.08vw',
                zIndex: 1,
                background: '#e5e5e5',
                width: '100%',
            }}
        >
            <Stack direction="row" spacing={3}>
                <CustomButton
                    disableRipple
                    onClick={() => {
                        if (error) {
                            setIsOpenError(!isOpen);
                        } else {
                            setModalType('Sign');
                            setIsOpenChosen(!isOpen);
                        }
                    }}
                    variant="contained"
                    disabled={!signOrDeclineButtonActive || !documentsID?.length || isButtonsDisabled}
                    sx={globalStyle.customStyleButton}
                    startIcon={signOrDeclineButtonActive ? <Signature /> : <SignatureDisabled />}
                >
                    Подписать
                </CustomButton>
                <CustomButton
                    disableRipple
                    variant="contained"
                    disabled={!signOrDeclineButtonActive || !documentsID?.length} // check disabled
                    sx={globalStyle.customStyleButton}
                    startIcon={signOrDeclineButtonActive ? <CancelIcon /> : <CancelIconDisabled />}
                    onClick={() => {
                        declineFiles();
                    }}
                >
                    Отказать
                </CustomButton>
                <CustomButton
                    disableRipple
                    variant="contained"
                    disabled={!documentsID?.length || isButtonsDisabled || isDownloading}
                    sx={globalStyle.customStyleButton}
                    startIcon={!documentsID?.length || isButtonsDisabled ? <DownloadIconDisabled /> : !isDownloading ? <DownloadIcon /> : undefined}
                    onClick={() => {
                        downloadFiles();
                    }}
                >
                    {isDownloading ? (
                        <CircularProgress
                            size="1.5rem"
                            sx={{
                                color: '#949DAF',
                                padding: '0.5rem',
                            }}
                        />
                    ) : (
                        'Скачать'
                    )}
                </CustomButton>
                {!isArchive && (
                    <CustomButton
                        disableRipple
                        variant="contained"
                        disabled={!documentsID?.length}
                        sx={globalStyle.customStyleButton}
                        startIcon={documentsID?.length ? <ArchiveActiveIcon /> : <ArchiveDisableIcon />}
                        onClick={() => setIsOpenModalArchive(true)}
                    >
                        {isLoadingArchive ? (
                            <CircularProgress
                                size="1.5rem"
                                sx={{
                                    color: '#949DAF',
                                    padding: '0.5rem',
                                }}
                            />
                        ) : (
                            ' В архив'
                        )}
                    </CustomButton>
                )}
                <CustomButton
                    disableRipple
                    onClick={() => {
                        setModalType('Delete');
                        setIsOpenChosen(!isOpen);
                    }}
                    disabled={!documentsID?.length}
                    variant="contained"
                    sx={globalStyle.customStyleButton}
                    startIcon={documentsID?.length ? <TrashIcon /> : <TrashIconDisabled />}
                >
                    Удалить
                </CustomButton>
            </Stack>
            <Stack sx={{ ...globalStyle.styleStack, borderBottom: scroll ? '' : '1px solid #E1E4E8' }}>
                {columns.map((column) => (
                    <Box
                        width={column.width}
                        height={'50px'}
                        justifyContent={'left'}
                        display={'flex'}
                        key={column.id}
                        alignItems={'end'}
                        paddingBottom={'9px'}
                    >
                        <Typography variant="inherit" sx={globalStyle.styleHead} key={column.id}>
                            {column.label}
                        </Typography>
                    </Box>
                ))}
            </Stack>
            <ModalSign
                uncheckAll={uncheckAll}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                setIsOpenSigned={setIsOpenSigned}
                setIsOpenWaiting={setIsOpenWaiting}
                setError={setError}
                documentsID={documentsID}
                setDocumentsID={setDocumentsID}
                dataURLtoFile={dataURLtoFile}
                setDocsCount={setDocsCount}
                refetch={refetch}
                readFileRecursive={readFileRecursive}
            />
            <ModalWaiting isOpen={isOpenWaiting} setIsOpen={setIsOpenWaiting} documentsID={documentsID} docsCount={docsCount} />
            <ModalChoose
                isOpen={isOpenChosen}
                setIsOpen={setIsOpenChosen}
                documentsID={documentsID}
                setIsOpenCerts={setIsOpen}
                modalType={modalType}
                deleteFiles={deleteFiles}
            />
            <ModalSignSending isOpen={isOpenSigned} setIsOpen={setIsOpenSigned} />
            <ModalError isOpen={isOpenError} setIsOpen={setIsOpenError} />
            <ModalArchive
                isOpen={isOpenModalArchive}
                onClose={() => setIsOpenModalArchive(false)}
                countDocuments={documentsID?.length}
                putDocumentsInArchive={putDocumentsInArchive}
                uncheckAll={uncheckAll}
            />
        </Box>
    );
}
