import * as React from 'react';

import Button from '@mui/material/Button';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import styled from '@emotion/styled';

import globalStyle from '../DocumentComponents/customStyleButton';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { useEffect } from 'react';
import { useState } from 'react';

const CustomButton = styled(Button)(({ theme }) => ({
    '&:hover, &.Mui-focusVisible': {
        backgroundColor: '#8AB528',
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.15,
        },
        '& .MuiImageMarked-root': {
            opacity: 0,
        },
        '& .MuiTypography-root': {
            border: '4px solid currentColor',
        },
    },
}));

export default function ButtonsPanelInComing({ handleClick, headerColumns, sortedColumnId }) {
    // const setArrowIcon = (columnId, isSorted) => {
    //     if (columnId === sortedColumnId) {
    //         if (isSorted) return <ArrowDownward sx={{ width: '1.04vw', height: '1.04vw' }} />;
    //         else return <ArrowUpward sx={{ width: '1.04vw', height: '1.04vw' }} />;
    //     }
    // };

    return (
        <Box sx={{ position: 'sticky', top: 0, zIndex: 1, background: '#e5e5e5', width: '100%' }}>
            <Stack sx={globalStyle.styleStack}>
                {headerColumns.map((column) => (
                    <Box
                        width={column.width}
                        height={'50px'}
                        justifyContent={'left'}
                        display={'flex'}
                        key={column.id}
                        alignItems={'end'}
                        paddingBottom={'9px'}
                    >
                        <Typography
                            variant="inherit"
                            sx={{ ...globalStyle.styleHead, display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                            key={column.id}
                            onClick={() => handleClick(column.id)}
                        >
                            {column.isSortedUp && <ArrowUpward sx={{ width: '1.04vw', height: '1.04vw' }} />}
                            {column.isSortedDown && <ArrowDownward sx={{ width: '1.04vw', height: '1.04vw' }} />}
                            {column.label}
                        </Typography>
                    </Box>
                ))}
            </Stack>
        </Box>
    );
}
