import ArchiveActiveIcon from '../svgComponents/ArchiveActiveIcon';
import ArchiveDisableIcon from '../svgComponents/ArchiveDisableIcon';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DownloadIcon from '../svgComponents/DownloadIcon';
import DownloadIconDisabled from '../svgComponents/DownloadIconDisabled';
import LoadingButton from '@mui/lab/LoadingButton';
import ModalChoose from '../Modal/ModalChoose';
import ModalExpireCertificate from '../Modal/ModalExpireCertificate';
import React, { useContext, useState } from 'react';
import TrashIcon from '../svgComponents/TrashIcon';
import TrashIconDisabled from '../svgComponents/TrashIconDisabled';
import ValidIcon from '../svgComponents/ValidIcon';
import ValidIconDisabled from '../svgComponents/ValidIconDisabled';
import api from '../../api/api';
import globalStyle from './customStyleButton';
import styled from '@emotion/styled';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ScrollContext } from '../../helpers/ScrollContext';
import ModalArchive from '../Modal/ModalArchive';

const CustomButton = styled(LoadingButton)(({ theme }) => ({
    color: '#181818',
    '&:hover, &.Mui-focusVisible': {
        backgroundColor: '#BDBDBD',
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.15,
        },
        '& .MuiImageMarked-root': {
            opacity: 0,
        },
        '& .MuiTypography-root': {
            border: '4px solid currentColor',
        },
    },
}));

export default function ButtonsPanelOutGoing({
    downloadFiles,
    deleteFiles,
    documentsID,
    isButtonsDisabled,
    revalidateFiles,
    revalidateButtonActive,
    isOpenModalExpireCertificate,
    setIsOpenModalExpireCertificate,
    isDownloading,
    isLoadingButton,
    isArchive,
    setDocumentsID,
    refetch,
    uncheckAll,
    setIsOpenModalArchive,
    isOpenModalArchive,
}) {
    const [isOpen] = useState(false);
    const [isOpenChosen, setIsOpenChosen] = useState(false);
    const [modalType, setModalType] = useState('');
    const { scroll } = useContext(ScrollContext);
    const [isLoadingArchive, setIsLoadingArchive] = useState(false);

    const columns = [
        { id: 'check', label: '', width: '7%' },
        { id: 'date', label: 'Дата', width: '9.8%' },
        { id: 'sender', label: 'Получатель', width: '18%' },
        {
            id: 'document',
            label: 'Пакет документов, ID',
            width: '27.2%',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'statusDocument',
            label: 'Статус пакета документов',
            width: '19%',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'signatureStatus',
            label: 'Статус подписи',
            width: '15%',
            format: (value) => value,
        },
    ];

    const setRevalidateIcon = () => {
        if (isLoadingButton) {
            return null;
        }

        if (!isButtonsDisabled && revalidateButtonActive) {
            return <ValidIcon />;
        } else {
            return <ValidIconDisabled />;
        }
    };

    const putDocumentsInArchive = async () => {
        setIsLoadingArchive(true);
        const res = await api.patch('/archive', {
            documents_type: 'outgoing',
            document_workflow_ids: documentsID,
        });

        setDocumentsID([]);
        uncheckAll();
        await refetch('/outgoing-documents');
        setIsLoadingArchive(false);
    };

    return (
        <Box
            sx={{
                boxShadow: scroll ? '0px 3px 5px rgba(0, 0, 0, 0.2)' : 'none',
                pt: '1.042vw',
                position: 'sticky',
                marginLeft: '-2.08vw',
                paddingLeft: '2.08vw',
                top: 0,
                zIndex: 1,
                background: '#e5e5e5',
                width: '100%',
            }}
        >
            <Stack direction="row" spacing={3}>
                <CustomButton
                    disableRipple
                    disabled={!documentsID?.length || isButtonsDisabled || isDownloading}
                    variant="contained"
                    sx={globalStyle.customStyleButton}
                    startIcon={isButtonsDisabled || !documentsID?.length ? <DownloadIconDisabled /> : !isDownloading ? <DownloadIcon /> : undefined}
                    onClick={() => {
                        downloadFiles();
                    }}
                >
                    {isDownloading ? (
                        <CircularProgress
                            size="1.5rem"
                            sx={{
                                color: '#949DAF',
                                padding: '0.5rem',
                            }}
                        />
                    ) : (
                        'Скачать'
                    )}
                </CustomButton>
                <CustomButton
                    disableRipple
                    loading={isLoadingButton}
                    variant="contained"
                    disabled={!revalidateButtonActive || !documentsID?.length || isButtonsDisabled}
                    sx={{ ...globalStyle.customStyleButton, width: '16.7vw' }}
                    startIcon={setRevalidateIcon()}
                    onClick={revalidateFiles}
                >
                    {!isLoadingButton && 'Перевалидировать подпись'}
                </CustomButton>
                {!isArchive && (
                    <CustomButton
                        disableRipple
                        variant="contained"
                        disabled={!documentsID?.length}
                        sx={globalStyle.customStyleButton}
                        startIcon={documentsID?.length ? <ArchiveActiveIcon /> : <ArchiveDisableIcon />}
                        onClick={() => setIsOpenModalArchive(true)}
                    >
                        {isLoadingArchive ? (
                            <CircularProgress
                                size="1.5rem"
                                sx={{
                                    color: '#949DAF',
                                    padding: '0.5rem',
                                }}
                            />
                        ) : (
                            'В архив'
                        )}
                    </CustomButton>
                )}
                <CustomButton
                    disableRipple
                    disabled={!documentsID?.length}
                    onClick={() => {
                        setModalType('Delete');
                        setIsOpenChosen(!isOpen);
                    }}
                    variant="contained"
                    sx={globalStyle.customStyleButton}
                    startIcon={documentsID?.length ? <TrashIcon /> : <TrashIconDisabled />}
                >
                    Удалить
                </CustomButton>
            </Stack>
            <Stack sx={{ ...globalStyle.styleStack, borderBottom: scroll ? '' : '1px solid #E1E4E8' }}>
                {columns.map((column) => (
                    <Box
                        width={column.width}
                        height={'50px'}
                        justifyContent={'left'}
                        display={'flex'}
                        key={column.id}
                        alignItems={'end'}
                        paddingBottom={'9px'}
                    >
                        <Typography variant="inherit" sx={globalStyle.styleHead} key={column.id}>
                            {column.label}
                        </Typography>
                    </Box>
                ))}
            </Stack>
            <ModalChoose
                isOpen={isOpenChosen}
                setIsOpen={setIsOpenChosen}
                documentsID={documentsID}
                modalType={modalType}
                deleteFiles={deleteFiles}
            />
            <ModalExpireCertificate
                isOpen={isOpenModalExpireCertificate}
                setIsOpenModalExpireCertificate={setIsOpenModalExpireCertificate}
                documentsID={documentsID}
            />
            <ModalArchive
                isOpen={isOpenModalArchive}
                onClose={() => setIsOpenModalArchive(false)}
                countDocuments={documentsID?.length}
                putDocumentsInArchive={putDocumentsInArchive}
                uncheckAll={uncheckAll}
            />
        </Box>
    );
}
