import { Input, styled } from '@mui/material';

const CalendarInputStyled = styled(Input)({
    cursor: 'pointer',
    width: '100%',

    '& .MuiInputBase-input': {
        fontSize: '0.938vw',
        cursor: 'pointer',
        backgroundColor: '#fff',
    },
});

export default CalendarInputStyled;
