import { Paper, styled } from '@mui/material';

const CalendarInputPaper = styled(Paper)({
    border: '1px solid transparent',
    padding: '0.833vw 1.042vw',
    width: '100%',
    maxWidth: '19.583vw',
});

export default CalendarInputPaper;
