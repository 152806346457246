import Button from '../../Button';
import ButtonStyled from '../../ButtonStyled/ButtonStyled';
import CalendarButton from '../styles/CalendarButton';
import DashIcon from '../../../../component/svgComponents/DashIcon';
import DatePickerCalendars from './DatePickerCalendars';
import DatePickerSlot from './DatePickerSlot';
import DeleteIcon from '../../../../component/svgComponents/DeleteIcon';
import { Box, InputAdornment, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

const DatePickerBody = (props) => {
    const {
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        hoverDate,
        setHoverDate,
        leftMonth,
        setLeftMonth,
        rightMonth,
        setRightMonth,
        setOpen,
        setAlignment,
        applyCalendar,
        type,
        resetCalendar,
    } = props;

    const [startDateInput, setStartDateInput] = useState(startDate?.format('DD.MM.YYYY') || '');
    const [endDateInput, setEndDateInput] = useState(endDate?.format('DD.MM.YYYY') || '');

    useEffect(() => {
        if (startDate) {
            setStartDateInput(startDate?.format('DD.MM.YYYY'));
        }
        if (endDate) {
            setEndDateInput(endDate?.format('DD.MM.YYYY'));
        }
        if (!startDate && !endDate) {
            setStartDateInput('');
            setEndDateInput('');
        }
    }, [startDate, endDate]);

    const clearEndDateInput = () => {
        setEndDateInput('');
        setEndDate(null);
        setAlignment(null);
    };

    const clearStarDateInput = () => {
        setStartDateInput('');
        setStartDate(null);
        setEndDateInput('');
        setEndDate(null);
        setAlignment(null);
    };

    return (
        <Box>
            <Stack
                direction={'row'}
                sx={{
                    paddingBottom: '1.563vw',
                }}
            >
                <DatePickerSlot
                    value={startDateInput}
                    normal
                    placeholder={'ДД.ММ.ГГГГ'}
                    endAdornment={
                        <InputAdornment position="end" onClick={() => clearStarDateInput()}>
                            <DeleteIcon />
                        </InputAdornment>
                    }
                />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DashIcon />
                </Box>
                <DatePickerSlot
                    value={endDateInput}
                    normal
                    placeholder={'ДД.ММ.ГГГГ'}
                    endAdornment={
                        <InputAdornment position="end" onClick={clearEndDateInput}>
                            <DeleteIcon />
                        </InputAdornment>
                    }
                />
            </Stack>
            <DatePickerCalendars
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                hoverDate={hoverDate}
                setHoverDate={setHoverDate}
                leftMonth={leftMonth}
                setLeftMonth={setLeftMonth}
                rightMonth={rightMonth}
                setRightMonth={setRightMonth}
            />
            <Stack
                direction={'row'}
                spacing={2}
                sx={{
                    marginTop: '1.563vw',
                    width: '13.438vw',
                }}
            >
                <ButtonStyled
                    onClick={() => {
                        applyCalendar(startDate, endDate, type);
                        setOpen(false);
                    }}
                    variant="success"
                >
                    Применить
                </ButtonStyled>
                <ButtonStyled onClick={() => resetCalendar(setStartDate, setEndDate, setOpen)}>Сбросить</ButtonStyled>
            </Stack>
        </Box>
    );
};

export default DatePickerBody;
